import React, {useEffect} from "react";
import {MenuOpenStyle, ContentMenuHead, ContentMenuList} from "../../styles/Menu";
import {ItemNav} from "../../styles/HeaderStyles";
import {theme} from "../../constants/Theme";
import {ReactComponent as BurguerIcon} from "../../assets/icons/burguer.svg";
import {Button, ButtonLink} from "../../styles/ButtonStyles";
import {useLocation} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";
import {useSelector} from "react-redux";

export default function MenuMobile({isOpen, close}) {
   const {loggedIn} = useSelector((state) => state.auth);
   const location = useLocation();
   let prevPathname = usePrevious(`${location.pathname}${location.search}`);

   useEffect(() => {
      if (isOpen) {
         let currentPath = `${location.pathname}${location.search}`;
         if (prevPathname !== currentPath) {
            close();
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [location]);

   useEffect(() => {
      // cambio de modal
      if (isOpen) {
         // Evitar scroll en body
         document.body.style.overflow = "hidden";
      } else {
         // Reactivar scroll en body
         document.body.style.overflow = "initial";
      }
   }, [isOpen]);

   return (
      <MenuOpenStyle className={`${isOpen ? "open" : "close"}`}>
         <ContentMenuHead className={`${isOpen ? "open" : "close"}`}>
            <div>
               <ItemNav to="/" className="logoNav">
                  <img src={require("../../assets/brand/freeticket_text_black.png")} alt="freeticket" />
               </ItemNav>
            </div>
            <Button className="mobileOption" onClick={close}>
               <BurguerIcon width="1.5em" height="1.5em" fill={theme.background} />
            </Button>
         </ContentMenuHead>
         <ContentMenuList className={`${isOpen ? "open" : "close"}`}>
            <ButtonLink to="/">Inicio</ButtonLink>
            <ButtonLink to="/events">Eventos </ButtonLink>
            <ButtonLink to="/about">Acerca de </ButtonLink>
            <ButtonLink to="/podcasts">Podcasts</ButtonLink>
            {loggedIn && <ButtonLink to="/ticket_list">Mis tickets </ButtonLink>}
            {loggedIn ? (
               <ButtonLink to="/profile" className="isLogin">
                  Mi Perfil
               </ButtonLink>
            ) : (
               <ButtonLink to="?modal=login">Login</ButtonLink>
            )}
         </ContentMenuList>
      </MenuOpenStyle>
   );
}
