import React, {useEffect, useState, useRef} from "react";
import AnimatedView from "../../../containers/AnimatedView";
//import styled from "styled-components";
//import {useDispatch, useSelector} from "react-redux";
import Cover from "../../../components/cover/Cover";
import { Container, Grid, Paper, Box, Typography } from '@mui/material';
import axios from 'axios';
import {apiUrl} from "../../../services/ApiUrl";

export default function EventsList() {
   const viewRef = useRef(null);

   const [data, setData] = useState([]);

   useEffect(() => {
        axios.get(apiUrl+'podcasts/get_podcasts')
        .then(response => {

            console.log(response.data);
            setData(response.data.data);

        })
        .catch(err => {
            setData([]);
        });
   }, []);

   return (
      <>
         <AnimatedView showNav={false} ref={viewRef}>

            <Cover background={null}>
                <div style={{marginTop:"115px"}}>
                    <Container>
                        <Typography variant="h4" gutterBottom>Podcasts</Typography>
                        <br/>
                        <Grid container spacing={3}>

                            {data.map((podcast, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <div className="video-responsive">
                                    <iframe
                                        src={"https://www.youtube.com/embed/"+podcast.youtube_id}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                    </div>
                                </Grid>
                            ))}

                        </Grid>
                    </Container>
                </div>
            </Cover>

         </AnimatedView>
      </>
   );
}

