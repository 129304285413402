import React, {useState, useEffect} from "react";
import axios from 'axios';
import {EventListContainer} from "../../../styles/EventList";
import {apiUrl} from "../../../services/ApiUrl";
import {EventListEvent} from "../../../services/MetaEvents";
import {photosUrl} from "../../../services/PhotosUrl";
import { Container, Grid, Paper, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { format, set } from 'date-fns';
import { es } from 'date-fns/locale';


export default function EventList() {

  // Estado para almacenar los datos recibidos
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // Estado para almacenar errores
  const [error, setError] = useState(null);
  // Estado para controlar el "loading"
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState('all'); // Nuevo estado para el país seleccionado
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('all');
  const [showCities, setShowCities] = useState(false);

  useEffect(() => {
   axios.get(apiUrl+'event/next_events')
     .then(response => {

         let data = response.data.data;
         data.map((event) => {
            event.date = format(new Date(event.date), 'dd/MM/yyyy', { locale: es });
         });
         setData(data);
         setFilteredData(data);
         setLoading(false);

     })
     .catch(err => {
       setData([]);
       setError(err);
       setLoading(false);
     });

     EventListEvent()
     .then(response => response.json()) // Si deseas ver la respuesta
     .then(data => {
        console.log("/////////RESPONSE META DATA//////////");
        console.log(data);
     })
     .catch(err => {
        console.error("Error en el request a Meta:", err);
     });

   }, []); 
   
   const handleCountryChange = async (event) => {
      const selectedCountryValue = event.target.value;  // Usar el valor directamente del evento
      setSelectedCountry(selectedCountryValue);  // Actualizar el estado para futuros renderizados
   
      if (selectedCountryValue !== 'all') {
         setFilteredData(data.filter(event => event.country_id == selectedCountryValue));
      } else {
         setFilteredData(data);
      }
   
      let cities = [];
      if (selectedCountryValue !== 'all') {
         cities = await getCities(selectedCountryValue);  // Llamada a getCities con el valor actualizado
         console.log("cities", cities);
         setShowCities(true);
      } else {
         setShowCities(false);
         setCities([]);
      }
   };
   

   const handleSelectedCityChange = (event) => {
      const selectedCityValue = event.target.value;
      setSelectedCity(selectedCityValue);
      if (selectedCityValue !== 'all') {
         setFilteredData(data.filter(event => event.city_id == selectedCityValue));
      } else {
         setFilteredData(data.filter(event => event.country_id == selectedCountry));
      }
   };

   function getCities(country_id) {
      axios.post(apiUrl+'cities/get_cities', {"country": country_id})
      .then(response => {
         //console.log("ciudades obtenidas" + JSON.stringify(response.data.data));
         const activeCities = response.data.data.filter(city => city.status === "1");
         //console.log("ciudades activas" + activeCities);
         setCities(activeCities);
      })
      .catch(err => {
         setCities([]);
         console.error("Error en el request", err);
      });
   }

   return (
      <>
         <EventListContainer>

            <Container>
               <Typography variant="h4" gutterBottom>Eventos</Typography>

               <select value={selectedCountry} onChange={handleCountryChange} 
                  style={{
                     backgroundColor: "black", 
                     height: "40px", 
                     padding: "10px",
                     border: "solid 2px #E8D907",
                     borderRadius: "10px"
                  }}>
                  <option value="all">Todos los países</option>
                  <option value="1">México</option>
                  <option value="2">Colombia</option>
               </select>

               <br/><br/>

               { showCities &&
                  <select value={selectedCity} onChange={handleSelectedCityChange} 
                     style={{
                        backgroundColor: "black", 
                        height: "40px", 
                        padding: "10px",
                        border: "solid 2px #E8D907",
                        borderRadius: "10px"
                     }}
                  >
                     <option value="all">Todas las ciudades</option>
                     {
                        cities.map(city => (
                           <option key={city.id} value={city.id}>{city.name}</option>
                        ))
                     }
                  </select>
               }

               <br/><br/>

               <Grid container spacing={3}>
                  {
                  filteredData.map(event => (
                     <Grid item md={6} key={event.id}>
                        <Link 
                           to={{
                              pathname: "/event_info",
                              state: { event_id:event.id  }
                           }}
                        >
                           <Box 
                              component="img" 
                              alt="" 
                              src={`${photosUrl}${event.external_img}`} 
                              sx={{ width: '100%', height: 'auto' }} 
                           />
                        </Link>
                     </Grid>
                  ))
                  }
               </Grid>
            </Container>
         </EventListContainer>
      </>
   );
}
