let apiVersion = "v20.0";  // Asegúrate de agregar la 'v' para la versión
let pixelId = "1286753772711710";
const appId = "540039691861920";  // Coloca aquí tu appId
const appSecret = "59b4cdb34e18ba0e433ac9ff29a31ddb";  // Coloca aquí tu appSecret

// Función para obtener el App Access Token dinámicamente
const getAppAccessToken = async () => {
    const tokenUrl = `https://graph.facebook.com/oauth/access_token?client_id=${appId}&client_secret=${appSecret}&grant_type=client_credentials`;

    try {
        const response = await fetch(tokenUrl, { method: 'GET' });
        const data = await response.json();

        if (data.access_token) {
            return data.access_token;
        } else {
            throw new Error("Error fetching App Access Token");
        }
    } catch (error) {
        console.error("Error obtaining access token:", error);
        throw error;
    }
};

// Función para hacer una solicitud POST a la API de Meta con el token dinámico
export const EventListEvent = async () => {
    try {
        //const accessToken = await getAppAccessToken();  // Obtén el token
        const accessToken ="EAAHrKcnZCf6ABO9V6zcjfkaKuWrNyIqtRoUEm1MxHIhmMFP6ZAZCXOhbJVDa6zvWbeZBGRENMJyx4mKeJBinl48lewJgVAgR0KWYWoZA8idhBFGZBtmmoY0l1cZCsnYsQHsoD7UeXGmQHZA7Wpn6LPAo9tXqTNbKJwtBoqyjgpzhJFoFoSVjcPmbLwZDZD";  // Obtén el token
        const MetaEventsURL = `https://graph.facebook.com/${apiVersion}/${pixelId}/events?access_token=${accessToken}`;

        let data = {
            "event_name": "EventList",
            "event_time": Math.floor(Date.now() / 1000),  // Debe ser en segundos
            "user_data": {
                "client_ip_address": "123.123.123.123",
            },
            "event_source_url": "https://appfreeticket.com/events",
            "action_source": "website"
        };

        return fetch(MetaEventsURL, {
            method: "POST",
            body: JSON.stringify({ data: [data] }),  // Se envían los eventos como un array en "data"
            headers: {
                "Content-Type": "application/json",
            },
        });
    } catch (error) {
        console.error("Error sending event to Meta:", error);
    }
};

export const RegisterEvent = async () => {
    try {
        //const accessToken = await getAppAccessToken();  // Obtén el token
        const accessToken ="EAAHrKcnZCf6ABO9V6zcjfkaKuWrNyIqtRoUEm1MxHIhmMFP6ZAZCXOhbJVDa6zvWbeZBGRENMJyx4mKeJBinl48lewJgVAgR0KWYWoZA8idhBFGZBtmmoY0l1cZCsnYsQHsoD7UeXGmQHZA7Wpn6LPAo9tXqTNbKJwtBoqyjgpzhJFoFoSVjcPmbLwZDZD";
        const MetaEventsURL = `https://graph.facebook.com/${apiVersion}/${pixelId}/events?access_token=${accessToken}`;

        let data = {
            "event_name": "RegisterEvent",
            "event_time": Math.floor(Date.now() / 1000),  // Debe ser en segundos
            "user_data": {
                "client_ip_address": "123.123.123.123",
            },
            "event_source_url": "https://appfreeticket.com/events",
            "action_source": "website"
        };

        return fetch(MetaEventsURL, {
            method: "POST",
            body: JSON.stringify({ data: [data] }),  // Se envían los eventos como un array en "data"
            headers: {
                "Content-Type": "application/json",
            },
        });
    } catch (error) {
        console.error("Error sending event to Meta:", error);
    }
};

export const GetTicketEvent = async () => {
    try {
        //const accessToken = await getAppAccessToken();  // Obtén el token
        const accessToken ="EAAHrKcnZCf6ABO9V6zcjfkaKuWrNyIqtRoUEm1MxHIhmMFP6ZAZCXOhbJVDa6zvWbeZBGRENMJyx4mKeJBinl48lewJgVAgR0KWYWoZA8idhBFGZBtmmoY0l1cZCsnYsQHsoD7UeXGmQHZA7Wpn6LPAo9tXqTNbKJwtBoqyjgpzhJFoFoSVjcPmbLwZDZD";
        const MetaEventsURL = `https://graph.facebook.com/${apiVersion}/${pixelId}/events?access_token=${accessToken}`;

        let data = {
            "event_name": "GetTicketEvent",
            "event_time": Math.floor(Date.now() / 1000),  // Debe ser en segundos
            "user_data": {
                "client_ip_address": "123.123.123.123",
            },
            "event_source_url": "https://appfreeticket.com/events",
            "action_source": "website"
        };

        return fetch(MetaEventsURL, {
            method: "POST",
            body: JSON.stringify({ data: [data] }),  // Se envían los eventos como un array en "data"
            headers: {
                "Content-Type": "application/json",
            },
        });
    } catch (error) {
        console.error("Error sending event to Meta:", error);
    }
};

export const LoginEvent = async () => {
    try {
        //const accessToken = await getAppAccessToken();  // Obtén el token
        const accessToken ="EAAHrKcnZCf6ABO9V6zcjfkaKuWrNyIqtRoUEm1MxHIhmMFP6ZAZCXOhbJVDa6zvWbeZBGRENMJyx4mKeJBinl48lewJgVAgR0KWYWoZA8idhBFGZBtmmoY0l1cZCsnYsQHsoD7UeXGmQHZA7Wpn6LPAo9tXqTNbKJwtBoqyjgpzhJFoFoSVjcPmbLwZDZD";
        const MetaEventsURL = `https://graph.facebook.com/${apiVersion}/${pixelId}/events?access_token=${accessToken}`;

        let data = {
            "event_name": "LoginEvent",
            "event_time": Math.floor(Date.now() / 1000),  // Debe ser en segundos
            "user_data": {
                "client_ip_address": "123.123.123.123",
            },
            "event_source_url": "https://appfreeticket.com/events",
            "action_source": "website"
        };

        return fetch(MetaEventsURL, {
            method: "POST",
            body: JSON.stringify({ data: [data] }),  // Se envían los eventos como un array en "data"
            headers: {
                "Content-Type": "application/json",
            },
        });
    } catch (error) {
        console.error("Error sending event to Meta:", error);
    }
};

