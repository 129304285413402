import React from "react";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import generateStore, {history} from "../ContextProvider";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import HomeView from "../public/views/home/HomeView";
import AboutView from "../public/views/about/AboutView";
import GlobalProvider from "../context/GlobalProvider";
//import StreamView from "../private/views/stream/StreamView";
import EventsProvider from "../context/EventsProvider";
import EventOnlineProvider from "../context/EventOnlineProvider";
import ProfileView from "../private/views/profile/ProfileView";
import EventsList from "../public/views/EventsList";
//import EventsList from "../public/views/home/EventsList";
import EventInfo from "../public/views/EventInfo/EventInfo";
import TicketList from "../private/views/ticket_list/ticket_list";
import ChangePassword from "../public/views/ChangePassword";
import Podcasts from "../public/views/podcasts/Podcasts";

const store = generateStore();

export default function AppRouter() {
   return (
      <Provider store={store}>
         <GlobalProvider>
            <EventOnlineProvider>
               <EventsProvider>
                  <ConnectedRouter history={history}>
                     <BrowserRouter>
                        <Switch>
                           <Route exact component={HomeView} path="/" />
                           <Route component={AboutView} path="/about" />
                           <Route component={ProfileView} path="/profile" />
                           <Route component={Podcasts} path="/podcasts" />
                           {/*<Route component={StreamView} path="/stream/:id" />*/}
                           <Route component={EventsList} path="/events" />
                           <Route component={EventInfo} path="/event_info" />
                           <Route component={TicketList} path="/ticket_list" />
                           <Route component={ChangePassword} path="/recover_password" />
                        </Switch>
                     </BrowserRouter>
                  </ConnectedRouter>
               </EventsProvider>
            </EventOnlineProvider>
         </GlobalProvider>
      </Provider>
   );
}
